import __NS_DYNAMIC_REACT_CONTENT___0 from "./app-bar.strings/content.default.js";
import { useContent, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement } from 'react';
import { useAnchor, useLocation, useRouteActive } from 'packages/react-nano-router';
import { Logo } from 'apps/acp/packages/logo-locator';
import { useTheme } from './theme';
import { NotificationBell } from './notification-bell';
import { AppBarContainer, IconSection, SectionCenter } from './app-bar.ccm.css';
export const AppBar = ({
  homeLink,
  notificationsLink,
  inverted
}) => {
  const {
    appBarWhiteBackgroundColor,
    appBarBorderBottomColor,
    appBarDefaultBackgroundColor
  } = useTheme();
  const {
    pathname
  } = useLocation();
  const isSelfEnrollmentDashboard = pathname.includes('self-enrollment');
  const isHome = useRouteActive(homeLink, {
    exact: true
  }) || isSelfEnrollmentDashboard;
  const homeAnchor = useAnchor(homeLink);
  const homeAltText = useContent("b1b4d4ca3db5e65e2fac331e13ef7225", {}, [], NS_DYNAMIC_REACT_CONTENT_MANAGER);
  return createElement(AppBarContainer.div, {
    $backgroundColor: inverted ? appBarWhiteBackgroundColor : appBarDefaultBackgroundColor,
    $borderBottomColor: appBarBorderBottomColor,
    $showOnMobile: isHome
  }, createElement(IconSection.div, null), createElement(SectionCenter.div, null, createElement("a", homeAnchor, createElement(Logo, {
    alt: homeAltText
  }))), createElement(IconSection.div, null, createElement(NotificationBell, {
    notificationsLink: notificationsLink,
    color: inverted ? 'primary' : 'white'
  })));
};