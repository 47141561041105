
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.AppBarContainer_5f505ff49ebb1ab7{background-color:var(--backgroundColor_82e788c142bf7592);height:48px;min-height:48px;padding:0 16px;display:none;align-items:center;color:#fff;border-bottom:1px solid var(--borderBottomColor_794defdc97ae3222);position:relative;top:0;z-index:10;position:fixed;width:100%}.AppBarContainer_5f505ff49ebb1ab7.showOnMobile_7b7114fcf180370a{display:flex}.IconSection_04d5b77a5a3540b9{width:24px;height:24px;align-items:center;display:flex}.SectionCenter_043f1c1dd0f51ecf{display:flex;align-items:center;justify-content:center;flex-grow:1;height:32px;text-align:center}@media (min-width:600px){.AppBarContainer_5f505ff49ebb1ab7{display:flex;padding:0 24px}}`;
styleInject(_css)

/** AppBarContainer Props */
export type AppBarContainerCCM = {
  /** AppBarContainer Component Custom Properties */
  '$backgroundColor': string;
  '$borderBottomColor': string;

  /** AppBarContainer Modifier Flags */
  '$showOnMobile'?: boolean;
};
/** Base AppBarContainer component */
export const AppBarContainer: ComponentCreator<AppBarContainerCCM> = createComponentCreator({
  "name": "AppBarContainer",
  "base": "AppBarContainer_5f505ff49ebb1ab7",
  "prop": {
    "$backgroundColor": "--backgroundColor_82e788c142bf7592",
    "$borderBottomColor": "--borderBottomColor_794defdc97ae3222"
  },
  "mod": {
    "$showOnMobile": "showOnMobile_7b7114fcf180370a"
  }
});


/** IconSection Props */
export type IconSectionCCM = {
  /** IconSection Component Custom Properties */
  // No custom properties found

  /** IconSection Modifier Flags */
  // No modifiers classes found
};
/** Base IconSection component */
export const IconSection: ComponentCreator<IconSectionCCM> = createComponentCreator({
  "name": "IconSection",
  "base": "IconSection_04d5b77a5a3540b9",
  "prop": {},
  "mod": {}
});


/** SectionCenter Props */
export type SectionCenterCCM = {
  /** SectionCenter Component Custom Properties */
  // No custom properties found

  /** SectionCenter Modifier Flags */
  // No modifiers classes found
};
/** Base SectionCenter component */
export const SectionCenter: ComponentCreator<SectionCenterCCM> = createComponentCreator({
  "name": "SectionCenter",
  "base": "SectionCenter_043f1c1dd0f51ecf",
  "prop": {},
  "mod": {}
});

