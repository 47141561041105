import { createElement, Fragment } from 'react';
import { overlays } from './overlays';
import { useLayoutOverlayViewed } from './hooks';

const LogLayoutOverlay = ({
  id
}) => {
  useLayoutOverlayViewed(id);
  return null;
};

export const LayoutOverlay = ({
  id
}) => {
  const Overlay = overlays[id];

  if (!Overlay) {
    window.console.error(`Layout overlay with with id ${id} does not exist`);
    return null;
  }

  return createElement(Fragment, null, createElement(LogLayoutOverlay, {
    id: id
  }), createElement(Overlay, {
    id: id
  }));
};