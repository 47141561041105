import { RequestConfig } from 'packages/http-client/fetcher';
import { createJsonBodyRequestConfig } from 'packages/http-client/react-forms';

export type OverlayIds = 'DashboardOverlay:NavigationUpdate';

export interface OverlayResponse {
  id: OverlayIds;
}

export const createRespondRequest = (
  id: string,
  type: 'overlays'
): RequestConfig =>
  createJsonBodyRequestConfig({
    method: 'POST',
    url: `/v2/${type}/${id}/respond`,
    json: {},
    mutating: true
  });

export const createGetLayoutOverlayRequest = (): RequestConfig<
  OverlayResponse[]
> => ({
  method: 'GET',
  url: '/v2/overlays'
});

export const createLayoutOverlayViewedRequest = (
  id: OverlayIds
): RequestConfig<null> => ({
  method: 'POST',
  url: `/v2/overlays/${id}/view`
});
