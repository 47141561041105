import { useMemo } from 'react';
import { Matchable } from 'packages/links';
import { useLocation, useBaseUrl } from 'packages/react-nano-router';
import { useHttpQueryWithErrors } from 'packages/http-client/react';
import {
  OverlayResponse,
  createGetLayoutOverlayRequest,
  createLayoutOverlayViewedRequest,
  OverlayIds
} from './api';

const removeBaseUrl = (pathname: string, baseUrl: string) =>
  (pathname = pathname.startsWith(baseUrl)
    ? pathname.slice(baseUrl.length)
    : pathname);

export const useSomeRoutesActive = (
  links: Array<Matchable<any>>,
  exact: boolean
) => {
  const baseUrl = useBaseUrl();
  const { pathname } = useLocation();
  return useMemo(
    () =>
      !!links.find((link) =>
        link.match(removeBaseUrl(pathname, baseUrl), exact)
      ),
    [links, pathname, baseUrl, exact]
  );
};

export const useLayoutOverlay = (): OverlayResponse[] => {
  const [response, data] = useHttpQueryWithErrors(
    createGetLayoutOverlayRequest()
  );

  if (response.ok) {
    return data;
  }

  return [];
};

export const useLayoutOverlayViewed = (id: OverlayIds) => {
  return useHttpQueryWithErrors(createLayoutOverlayViewedRequest(id));
};
