import { createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    acpRisingTideLayout: Partial<AcpRisingTideLayoutTheme>;
  }
}

export interface AcpRisingTideLayoutTheme {
  appBarBorderBottomColor: string;
  appBarWhiteBackgroundColor: string;
  appBarDefaultBackgroundColor: string;
  bottomBarActiveIconColor: string;
  bottomBarIconColor: string;
  bottomBarWhiteBackgroundColor: string;
  layoutWhiteBackgroundColor: string;
  layoutGreyBackgroundColor: string;
  notificationCounterBackgroundColor: string;
  overlayBackground: string;
}

const [AcpRisingTideLayoutThemeProvider, useTheme] = createUseThemeHook(
  'acpRisingTideLayout',
  (global) => ({
    appBarBorderBottomColor: global.neutral.color300,
    appBarWhiteBackgroundColor: '#FFF',
    appBarDefaultBackgroundColor: global.default.color500,
    bottomBarActiveIconColor: global.special.color500,
    bottomBarIconColor: global.neutral.color500,
    bottomBarWhiteBackgroundColor: '#FFF',
    layoutWhiteBackgroundColor: '#FFF',
    layoutGreyBackgroundColor: global.neutral.color100,
    notificationCounterBackgroundColor: global.special.color400,
    overlayBackground: global.neutral.color800
  })
);

export { AcpRisingTideLayoutThemeProvider, useTheme };
